<template>
	<div class="signup" id="wrap">
		<div class="language_btn">
			<!--언어선택-->
			<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
				<div class="dep_01">
					<ul class="dep_02">
						<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
							<a href="javascript:;" @click="SetLocale(i)">
								<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div name="join_form" class="join_form">
			<div class="index_l_box">
				<div class="section_right_inner">
					<div class="s_left">
						<div class="withdrawal01">
							<div  v-html="term" class="fs-px-12"></div>
							<div class="flex-between-center mt-2 m-0 p-3 bg-primary w-100 h-100 fs-px-13 text-white">
								<div class="round_checkbox d-flex align-items-center">
									<input type="checkbox" v-model="check1" id="check1">
									<label for="check1" class=" cursor-pointer">
										<div class="chk_icon"></div>
										<small class="ps-2 conditions text-truncate lh-1">{{$t("term.1")}}</small>
									</label>
								</div>
								<span class="cursor-pointer fs-px-12 w-fit-content" @click="TermAgree()">{{ $t("term.2") }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			lang:this.$i18n.locale,
			language : this.$t('language.1'),
			langs: [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")],
			paramsLang: ['ko', 'en', 'jp', 'cn', 'vn', 'th'],
			langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'vtn.png', 'th.jpg'],
			langIndex: 0,
			term : '',
			check1 : false,
		}
	},
	created(){
		this.GetTerm();
		this.SetLanguage();
		const userid = this.$route.params.id
		const line = this.$route.params.line
		if(this.$route.params.id != undefined){
			this.recom_id = userid;
			// this.support_id = userid;
			
			this.link = true;
			this.CheckRecom();
			// this.CheckSupport();
		}
		
		if(this.$route.params.line != undefined){
			this.line = line;
			
			this.lien_check = true;
		}
		
	},
	methods:{
		SetLanguage: function() {
			var lang = this.$route.params.lang;
			if(lang == 'ko') {
				this.language =  this.$t("language.1");
				this.langIndex = 0;
			}else if(lang == 'en') {
				this.language = this.$t("language.2");
				this.langIndex = 1;
			}else if(lang == 'jp') {
				this.language = this.$t("language.3");
				this.langIndex = 2;
			}else if(lang == 'cn') {
				this.language = this.$t("language.4");
				this.langIndex = 3;
			}else if(lang == 'vn') {
				this.language = this.$t("language.5");
				this.langIndex = 4;
			}else if(lang == 'th') {
				this.language = this.$t("language.6");
				this.langIndex = 5;
			}else {
				this.language = this.$t("language.1");
				this.langIndex = 0;
			}
		},
		SetLocale: function(locale){
			this.$i18n.locale = this.paramsLang[locale];
		
			this.$router.push({
				params: {lang: this.paramsLang[locale]}
			})
			this.langs = [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")];
			this.language = this.langs[locale];
			this.langIndex = locale;
			this.$router.go(0);
			
		},
		TermAgree(){
			const check = this.check1;

			if(check ==false){
				this.$alert(this.$t('term.3'));
				return false;
			}
			

			this.$http.post('/member/common/TermAgree',{}).then(
				(res) =>  {
					if(res.status == 200){
						if(res.data.code =="200"){
							this.$router.replace({path : "/"});
						}
					}
				}   
			)
		},
		GetTerm(){
			const lang = this.lang;
			const body = {lang};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post('/member/common/GetTerm',{req}).then(
				(res) =>  {
					if(res.status == 200){
						

						if(res.data.code =="200"){
							this.loading = false;
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)

							this.term = body.info.memo;
						}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
					}
				}   
			)
		},
		
	},
	
}
</script>
<style scoped>
	    .join_textsrea {
	        width: 90%;
	        margin-left: 5%;
	    }
		
	    .index_l_box {
	        min-height: 100vh;
	        width: 100%;
	    }
	
		.join_textsrea textarea{
			width:100%;
			height:80px;
	        border-radius: 5px;
			border-color: #ddd;
			font-size: 12px;
			padding: 10px;
		}
		/* 
	    .account{
	        font-size: 14px;
	    }
		.index_input01 {
	        height: 35px;
		}
		.phone_select {
	        height: 35px;
		}
		.index_input02 {
	        height: 35px;
		}
		.index_input03 {
	        height: 35px;
		}
		.form02_span {
	        height: 35px;
		}
		.button02 {
	        height: 45px;
		}
		.button03 {
	        height: 45px;
	    } */
	    
	    /* custom */
	    /* @media screen and (min-width:1024px){
	        .join_div{
	            max-height: 95vh;
	            overflow-y: scroll;
	        }
	    }
	    @media screen and (max-width:1023px){
	        .join_div{
	            max-height: auto;
	            overflow-y: auto;
	        }
	    } */

		.country_count{
			width: 90%;
			margin: 0 auto;
			margin-bottom: 15px;
			font-size: 12px;
		}
		
</style>

